//
// Iconbox
//

.icon-box {
  padding: 38px 45px;
  padding: 30px 10px;

  .icon-box-icon {
    display: inline-block;
    padding: 0 2px;
    margin: 0 0 20px;
    overflow: hidden;
    line-height: 1;
    font-size: rem(38);
    color: theme-color("brand");
    transition: color 0.35s ease;
  }

  .icon-box-title h6 {
    margin: 0 0 rem(12);
  }

  .icon-box-content > *:last-child {
    margin-bottom: 0;
  }

  .icon-box-link {
    margin: 10px 0 0;
  }

  &:hover .icon-box-icon {
    color: $headings-color;
    animation: toRightFromLeft 0.35s forwards;
  }
}

@-webkit-keyframes toRightFromLeft {

  49% {
    -webkit-transform: translate(100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translate(-100%);
  }

  51% {
    opacity: 1;
  }

}

@-moz-keyframes toRightFromLeft {

  49% {
    -moz-transform: translate(100%);
  }

  50% {
    opacity: 0;
    -moz-transform: translate(-100%);
  }

  51% {
    opacity: 1;
  }

}

@keyframes toRightFromLeft {

  49% {
    transform: translate(100%);
  }

  50% {
    opacity: 0;
    transform: translate(-100%);
  }

  51% {
    opacity: 1;
  }

}