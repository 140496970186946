//
// Footer
//

.footer {
  color: $body-color !important;

  a:not(.btn) {
    color: $body-color !important;

    &:hover,
    &:focus {
      color: color("white")!important;
    }
  }

  .widget-categories ul li,
  .widget-recent-entries ul li,
  .widget-recent-entries-custom ul li {
    border-color: lighten($footer-background, 5%);
  }

  .form-control {
    background: darken($footer-background, 2%);
    border-color: darken($footer-background, 2.5%);
  }
}

.footer-widgets {
  padding: $footer-padding-y 0 ($footer-padding-y - $widget-margin-y);

  & + .footer-bar {
    border-top: 1px solid lighten($footer-background, 4%);
  }
}

.footer-bar {
  padding: 30px 0;

  p {
    margin-bottom: 0;
  }
}

