//
// Filters
//

.filters {
    text-align: center;
    list-style: none;
    font-size: rem(16px);
    margin: 0 0 15px;
    padding: 0;

    > li {
        display: inline-block;
        margin: 0 15px 20px 15px;
    }

    a {
      opacity: .6;
      color: $body-color;

      &:hover,
      &.current {
        opacity: 1;
      }
    }
}

//
// Portfolio Grid
//

.row-portfolio {
  margin-right: -10px;
  margin-left: -10px;

  .container & {
    margin-right: -5px;
    margin-left: -5px;
  }

  .grid-sizer,
  .portfolio-item {
    overflow: hidden;
    padding: 0 5px 10px;
    float: left;
    width: 50%;
  }

  &[data-columns="3"] .grid-sizer,
  &[data-columns="3"] .portfolio-item {
    width: 33.333333%;
  }

  &[data-columns="4"] .grid-sizer,
  &[data-columns="4"] .portfolio-item {
    width: 25%;
  }

  &[data-columns="5"] .grid-sizer,
  &[data-columns="5"] .portfolio-item {
    width: 20%;
  }
}

@mixin portfolio-variant($background) {

  .portfolio-item-title,
  .portfolio-item-subtitle {
    color: color-yiq($background);
  }

  .portfolio-item-img::after {
    background: $background;
  }

}


//
// Portfolio Grid Item
//

.portfolio-item {
  background: transparent !important;

  @each $color, $value in $theme-colors {
    &.bg-#{$color} {
      @include portfolio-variant($value);
    }
  }
}

.portfolio-item-wrapper {
  position: relative;
  overflow: hidden;
}

.portfolio-item-img {
  position: relative;

  &:after {
    background: theme-color("brand");
    position: absolute;
    display: block;
    content: "";
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
}

.portfolio-item-img {
  transition: transform 5s ease-in-out;
}

.portfolio-item-caption {
  transition: all 0.3s ease-in-out;
}

.portfolio-item-caption {
  position: absolute;
  padding: rem(30px);
  width: 100%;
  top: 100%;
  opacity: 0;
  left: 0;
  z-index: 1;
}

.portfolio-item-title {
  letter-spacing: (.5px);
  font-size: rem(16px);
  margin-bottom: 0;
  color: #fff;
}

.portfolio-item-subtitle {
  font-size: rem(13px);
  opacity: .7;
  color: #fff;
}

.portfolio-item-link {
  position: absolute;
  z-index: 3;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

.portfolio-item:hover .portfolio-item-img {
  transform: scale(1.2);
}

.portfolio-item:hover .portfolio-item-img::after {
  opacity: .8;
}

.portfolio-item:hover .portfolio-item-caption {
  transform: translateY(-100%);
  opacity: 1;
}


//
// Portfolio Carousel Item
//

.portfolio-carousel {

  .portfolio-item-wrapper {
    border-radius: $border-radius;
  }

  .owl-prev,
  .owl-next {
    background: color("white");
    height: 54px;
    width: 54px;
    transform: translateY(50%);
    margin-top: -($spacer * 5);
    line-height: 54px;
    font-size: rem(12px);
    color: $headings-color;
  }

  .owl-prev {
    left: 5px;

    &:hover {
      left: 0;
    }
  }

  .owl-next {
    right: 5px;

    &:hover {
      right: 0;
    }
  }
}

.portfolio-carousel {
  margin-bottom: $spacer;

  .portfolio-item {
    border-radius: $border-radius;
    pointer-events: none;
    cursor: default;
    box-shadow: 0 20px 70px 0 rgba(0, 0, 0, .15);
    margin-bottom: $spacer * 5;

    img {
      border-radius: $border-radius;
    }
  }

  .owl-item.active.center .portfolio-item {
    pointer-events: auto;
    cursor: auto;
  }

  .owl-dots {
    text-align: center;
    margin: -($spacer * 3) 0 0 20px;
  }
}

.work-nav {
  overflow: hidden;

  img {
    width: 60px;
  }
}

.work-nav-text {
  font-size: $font-size-base;
  margin: 0;
}

.work-nav-prev,
.work-nav-next {
  transition:  all .7s cubic-bezier(.25, 1, .33, 1);
  display: inline-block;

  &:hover {
    transform: translateX(0);
  }
}

.work-nav-prev {
  transform: translateX(-(rem(60px) + $spacer));
}

.work-nav-next {
  transform: translateX((rem(60px) + $spacer));
}


//
// Media
//

@include media-breakpoint-down(lg) {

  .row-portfolio {

    &[data-columns="4"] .grid-sizer,
    &[data-columns="4"] .portfolio-item {
      width: 33.333333%;
    }

    &[data-columns="5"] .grid-sizer,
    &[data-columns="5"] .portfolio-item {
      width: 33.333333%;
    }
  }

}

@include media-breakpoint-down(md) {

  .row-portfolio {

    .grid-sizer,
    .portfolio-item {
      width: 50%;
    }

    &[data-columns="3"] .grid-sizer,
    &[data-columns="3"] .portfolio-item {
      width: 50%;
    }

    &[data-columns="4"] .grid-sizer,
    &[data-columns="4"] .portfolio-item {
      width: 50%;
    }

    &[data-columns="5"] .grid-sizer,
    &[data-columns="5"] .portfolio-item {
      width: 50%;
    }
  }

}

@include media-breakpoint-down(sm) {

  .row-portfolio {

    .grid-sizer,
    .portfolio-item {
      width: 100%;
    }

    &[data-columns="3"] .grid-sizer,
    &[data-columns="3"] .portfolio-item {
      width: 100%;
    }

    &[data-columns="4"] .grid-sizer,
    &[data-columns="4"] .portfolio-item {
      width: 100%;
    }

    &[data-columns="5"] .grid-sizer,
    &[data-columns="5"] .portfolio-item {
      width: 100%;
    }
  }

}

@include media-breakpoint-down(sm) {

  .work-nav-text {
    display: none;
  }

  .work-nav-prev,
  .work-nav-next {
    transform: translateX(0);
  }

}