//
// Widgets
//

.widget {
  margin-bottom: $widget-margin-y;

  .widget-title {
    margin-bottom: 24px;

    > h6 {
      letter-spacing: rem(.5);
      font-size:  rem(15px);
      margin: 0;
    }
  }

  a:not(.btn) {
    color: $body-color;

    &:hover,
    &:focus {
      color: $link-color;
    }
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  ul li {
    padding-bottom: 10px;
  }
}

// Search

.widget-search {
  padding: 0;
  border: 0;

  form {
    position: relative;
  }

  .search-button {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 50%;
    height: 100%;
    right: $input-padding-x;
    transform: translateY(-50%);
    transition: all 0.3s ease-out;
    color: #ddd;

    &:focus,
    &.focus,
    &.active.focus,
    &.active:focus,
    &:active.focus,
    &:active:focus {
      outline: 0;
    }

    &:hover {
      color: $link-color;
    }
  }
}

.widget-categories ul li,
.widget-recent-entries ul li,
.widget-recent-entries-custom ul li {
  border-bottom: 1px solid $border-color;
  padding: 10px 0;
  margin: 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: 0;
    padding-bottom: 0;
  }
}

.widget-recent-entries-custom ul li {
  padding: 12px 0;
}

// Custom Recent Entries

.widget-recent-entries-custom {

  ul li {
    display: table;

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .wi,
  .wb {
    vertical-align: middle;
    display: table-cell;
  }

  .wi {
    float: left;
    width: 64px;

    img {
      border-radius: $border-radius;
      width: 100%;
    }
  }

  .wb {
    padding-left: 16px;
    width: 100%;
  }
}

.post-date {
  font-size: rem(11px);
  color: $text-muted;
  display: block;
  margin: 0;
}

// Twitter Feed Widget

.twitter-feed-widget ul > li {
  border: 0;
  margin: 0 0 20px;

  &:last-child {
    padding: 0;
    margin: 0;
  }
}

.twitter-feed-widget .timePosted {
  font-size: rem(11px);
  margin: 4px 0 0px;
}

.twitter-feed-widget .tweet {
  word-wrap: break-word;
  margin: 0;
}

.twitter-feed-widget .user,
.twitter-feed-widget .interact {
  display: none;
}

// Recent Works

.widget-recent-works > ul {
  @include clearfix();
  margin: 0 -4px;

  > li {
    border: 0 !important;
    float: left;
    width: 33.333%;
    padding: 4px;
    margin: 0;

    > a:hover {
      opacity: 0.5;
    }
  }
}