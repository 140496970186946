//
// Custom Card Variant
//

@mixin card-variant($background) {
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    color: color-yiq($background);
  }

  a {
    color: color-yiq($background);
    &:hover,
    &:focus {
      color: rgba(color-yiq($background), .9);
    }
  }

  &::after {
    background: $background;
  }
}