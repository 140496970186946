//
// Tabs
//

.nav-tabs {

  .nav-link {
    border-color: transparent;
    border-width: 0 0 $nav-tabs-link-active-border-width 0;
    font-weight: 500;
    color: $headings-color;
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    border-color: $nav-tabs-link-active-border-color;
    border-bottom-width: $nav-tabs-link-active-border-width;

    > h6 {
      color: theme-color("brand");
    }
  }
}

//
// Tabbable tabs
//

.tab-content {
  padding: $tabs-padding-y $tabs-padding-x;

  > .tab-pane > *:last-child {
    margin-bottom: 0;
  }
}

