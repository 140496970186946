//
// Modules
//

.module,
.module-cover {
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: $body-bg;
  background-size: cover;
  padding: $module-padding-top 0 $module-padding-bottom;
}

.parallax {
  background-color: transparent;

  .container,
  .container-fluid {
    position: relative;
    z-index: 2;
  }
}

.bg-gray {
  background-color: $module-gray-background;
}

.overlay-background {
  background: $module-overlay-color;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;

  &.overlay-gradient {
    background-image: linear-gradient(315deg, $module-gradient-left 33%, $module-gradient-right);
  }
}

.divider-top {
  border-top: 1px solid $border-color;
}

.divider-bottom {
  border-bottom: 1px solid $border-color;
}