//
// Tooltip
//

.tooltip-inner {
  font-size: $tooltip-font-size;
  color: inherit;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}

