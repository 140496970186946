//
// Buttons
//

.btn {
  letter-spacing: $btn-letter-spacing;
  font-size: $btn-font-size;
  cursor: pointer;
  transition:  all .45s cubic-bezier(0.25, 1, 0.33, 1);

  &:hover {
    box-shadow: 0 20px 38px rgba(0, 0, 0, 0.16)!important;
    transform: translateY(-3px);
  }

  .input-group &:hover {
    box-shadow: none !important;
    transform: translateY(0);
  }

  &:focus,
  &.focus {
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
  }

  &.btn-circle {
    border-radius: rem(30px);
  }

  .btn-icon-left {
    margin-right: 4px;
  }

  .btn-icon-right {
    margin-left: 4px;
  }
}

//
// Buttons List
//

.btn-list {
  .btn {
    margin: 4px 10px 4px 0;
  }
  &.text-right .btn {
    margin: 4px 0 4px 10px;
  }
  &.text-center .btn {
    margin: 4px 5px;
  }
}


//
// Mobile Block Btn
//

@include media-breakpoint-down(sm) {

  .btn.btn-block-mobile {
    display: block;
    width: 100%;
  }

}