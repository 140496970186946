//
// Maps
//

.map-widget {
  height: 400px;
}

.map {
  height: 100% !important;
  width: 100%;

  &:before {
    display: none;
  }
}
