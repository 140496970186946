//
// Player
//

.popup-video {
  border-radius: $border-radius;
  box-shadow: 0 20px 70px 0 rgba(0, 0, 0, .15);
  overflow: hidden;
  position: relative;
  z-index: 0;

  .popup-video-play {
    background: rgba(theme-color("brand"), .6);
    border-radius: 50%;
    height: rem(100px);
    width: rem(100px);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      content: '';
      height: 0;
      width: 0;
      margin-left: rem(-5px);
      margin-top: rem(-8px);
      border-style: solid;
      border-width: 7px 0 7px 14px;
      border-color: transparent transparent transparent #ffffff;
    }
  }

  img {
    width: 100%;
    transition: all 0.3s ease;
  }
}

.popup-video:hover {

  img {
    transform: scale(1.05);
  }
}